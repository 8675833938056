import { FC, useContext } from "react";
import { Box } from "@otrium/core";
import { CategoryData } from "src/types/graphql.d";
import { CarouselHeader } from "src/molecules/CarouselHeader";
import { Carousel } from "src/molecules/Carousel";
import { CategoryCard, CategoryCardColor } from "src/molecules/CategoryCard";
import { AppCtx } from "src/contexts/app.context";
import { getI18nRoute } from "src/utils/i18nRoutes";
import { localeFix } from "src/utils/genderLocale/genderLocale";
import { Category } from "src/types/Category.d";

interface CategoryCarouselProps {
  title?: string | JSX.Element;
  subTitle?: string;
  headerLinkHref?: string;
  headerLinkAs?: string;
  categories?: Category[];
  color?: CategoryCardColor;
  genderId: string;
  slidesDesktop?: number;
  slidesTablet?: number;
  slidesMobile?: number;
  isDesigner?: boolean;
}

// TODO: HOTFIX UNTIL CONTENT MANAGERS MIGRATE EVERYTHING IN COMMERCETOOLS
// https://otrium.atlassian.net/browse/CN-1668

const getPlGenderId = (gender: string): string => {
  switch (gender) {
    case "women":
      return "kobiety";
    case "men":
      return "mezczyzni";
    case "kids":
      return "dzieci";
  }
  return "";
};

const checkUrl = (
  url: string,
  genderId: string,
  locale: string,
  isDesigner: boolean | undefined
) => {
  if (url) {
    if (locale === "pl-pl" && !url.includes("otrium.pl") && !isDesigner) {
      const engGender = localeFix({ gender: genderId });

      const [firstPartNewUrl, secondPartNewUrl] = url
        .replace(".nl", ".pl")
        .replace(".com", ".pl")
        .split(".pl");
      const [, productCategorie, , ...categorySlug] =
        secondPartNewUrl.split("/");
      const plGender = getPlGenderId(engGender);

      const newUrl = [
        firstPartNewUrl + ".pl",
        productCategorie,
        plGender,
        categorySlug[categorySlug.length - 2],
      ].join("/");

      return newUrl;
    } else {
      return url;
    }
  }

  return undefined;
};

const CategoryCarousel: FC<CategoryCarouselProps> = ({
  title,
  subTitle,
  headerLinkHref,
  headerLinkAs,
  categories,
  color,
  genderId,
  slidesDesktop,
  slidesTablet,
  slidesMobile,
  isDesigner = false,
}) => {
  const { locale } = useContext(AppCtx);
  return (
    <>
      <CarouselHeader
        title={title}
        subTitle={subTitle}
        href={genderId !== "kids" ? headerLinkHref : undefined}
        as={genderId !== "kids" ? headerLinkAs : undefined}
      />
      <Carousel
        // @ts-expect-error Fix types
        items={categories?.map((category: CategoryData) => {
          // TODO: HOTFIX UNTIL CONTENT MANAGERS MIGRATE EVERYTHING IN COMMERCETOOLS
          // https://otrium.atlassian.net/browse/CN-1668
          const url = checkUrl(category.url, genderId, locale, isDesigner);

          return (
            <Box key={category.id}>
              <CategoryCard
                size="m"
                category={category}
                link={
                  url
                    ? url
                    : isDesigner
                    ? `${getI18nRoute({
                        route: `/${genderId}/designer`,
                        locale,
                      })}/${category.slug}`
                    : `${getI18nRoute({
                        route: `/${genderId}`,
                        locale,
                      })}/${category.slug}`
                }
                color={color}
              />
            </Box>
          );
        })}
        slidesDesktop={slidesDesktop}
        slidesTablet={slidesTablet}
        slidesMobile={slidesMobile}
        marginSize="m"
        marginSizeMobile="m"
      />
    </>
  );
};

export default CategoryCarousel;
