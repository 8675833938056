import { Box, Flex } from "@otrium/core";
import { Content } from "src/atoms/Content";
import { ProductCardSkeleton } from "src/molecules/ProductCardSkeleton";
import { BrandCoverSkeleton } from "src/molecules/BrandCoverSkeleton";

const SkeletonRow = ({ width = [1 / 2, 1 / 2, 1 / 3] }) => (
  <Flex flexWrap="wrap" margin="auto">
    {[...(Array(6) as number[])].map((_value, index) => (
      <Box width={width} px={3} py={2} key={index}>
        <ProductCardSkeleton />
      </Box>
    ))}
  </Flex>
);

const HomeViewSkeleton = (): JSX.Element => (
  <Content>
    <Flex mx={-2} flexDirection="column" alignItems="center">
      <Box px={[0, 0, 0, 2, 2]} ml={[0, 0, 0, 4, 4]} width={1}>
        <BrandCoverSkeleton />
      </Box>
      <Flex px={[0, 0, 0, 2, 2]} ml={[0, 0, 0, 4, 4]} width={1}>
        <SkeletonRow />
      </Flex>
    </Flex>
  </Content>
);

export default HomeViewSkeleton;
