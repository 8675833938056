import { useQuery } from "@apollo/react-hooks";
import { ApolloError, ApolloQueryResult } from "@apollo/client";

import gql from "graphql-tag";
import {
  Homepage,
  ProductLabelFilter,
  CarouselRecommendTypeInput,
} from "src/types/graphql.d";
import { useState, useEffect } from "react";
import { useFeatureFlags } from "./useFeatureFlags";

interface HomepageData {
  homepage: Homepage | undefined;
}
interface HomepageVars {
  gender: string;
  limit?: number;
  labels?: ProductLabelFilter[];
  carousel_recommend_type?: CarouselRecommendTypeInput;
}
interface HomepageHook {
  refetch: () => Promise<ApolloQueryResult<HomepageData>>;
  data: HomepageData | undefined;
  loading: boolean;
  error: ApolloError | undefined;
}

const GET_NEW_IN = gql`
  query homepage(
    $gender: String!
    $limit: Int!
    $labels: [ProductLabelFilter!]!
    $carousel_recommend_type: CarouselRecommendTypeInput
  ) {
    homepage(gender: $gender) {
      productBlocks(
        limit: $limit
        labels: $labels
        carousel_recommend_type: $carousel_recommend_type
      ) {
        type
        position
        data {
          is_enabled
          title
          url
          personalised
          products {
            id
            name
            thumbnail
            price
            regular_price
            slug
            brand {
              id
              name
            }
            translated_category_ids
            translated_category_names
            product_discount
            product_kids_gender
            product_shop_type
            is_personalised
            available_sizes
            labels {
              value
            }
            gate {
              level
            }
          }
          response_source_type
        }
      }
    }
  }
`;

const GET_SHOPS_HOMEPAGE = gql`
  query homepage($gender: String!) {
    homepage(gender: $gender) {
      Shops {
        type
        position
        url
        title
        data {
          id
          name
          discount
          subtitle
          slug
          genders
          is_coming_soon
          logo
          overlay_logo
          logo_svg
          image
          cover_image
          user_wishlist_item_id
          labels {
            type
            value
            color
            promotion {
              promo_code
              promo_message
              promo_message_url
            }
            priority
            text_color
          }
          gate {
            level
          }
        }
      }
    }
  }
`;

const GET_HOMEPAGE = gql`
  query homepage($gender: String!) {
    homepage(gender: $gender) {
      PromoBanners {
        type
        position
        data {
          title
          description
          link
          desktop_image
          mobile_image
          name
        }
      }

      PromotionBanners {
        type
        position
        data {
          title
          description
          banner_link
          tablet_image
          tablet_image_width
          tablet_image_height
          mobile_image
          mobile_image_width
          mobile_image_height
          desktop_image
          button_title
          button_colour
          condition_text
          background_colour
          text_colour
          type
          promo_code
          promo_code_colour
          start_date
          end_date
          hide_cta_button
          gated
        }
      }

      ImageBanners {
        type
        position
        data {
          block_uuid
          link
          title
          subtitle
          subtitle_color
          image_mobile
          image_desktop
          title_color
          platform
          gated
          display_type
          start_date
          end_date
        }
      }

      Departments {
        type
        position
        data {
          department_title
          department_subtitle
          department_slug
          brands {
            id
            name
            discount
            subtitle
            slug
            image
            overlay_logo
            logo_svg
            labels {
              type
              value
              color
              promotion {
                promo_code
                promo_message
              }
              priority
              text_color
            }
            gate {
              level
            }
            user_wishlist_item_id
          }
        }
      }

      Categories {
        type
        position
        data {
          id
          title
          subtitle
          banner_image
          banner_image_mobile
          url
          slug
          products {
            id
            user_wishlist_item_id
            brand {
              id
              name
              slug
              designer_brand
            }
            slug
            price_float
            regular_price_float
            thumbnail
            name
            translated_category_ids
            translated_category_names
            product_discount
            product_kids_gender
            product_shop_type
            is_personalised
            sizes_available
            labels {
              value
            }
            gate {
              level
            }
          }
        }
      }

      ActionBlocks {
        type
        position
        data {
          title
          subtitle
          description
          code
          image
          button_title
          button_link
        }
      }

      InspirationalSections {
        type
        position
        data {
          title
          subtitle
          link
          image
          linkCTA
          gated
        }
      }

      PopularCategories {
        type
        position
        gated
        data {
          id
          slug
          title
          thumbnail
          url
        }
      }

      ReferFriend {
        type
        position
        data {
          link
          title
          description
          action_button
          image
        }
      }
    }
  }
`;

type Cache =
  | "cache-first"
  | "network-only"
  | "cache-only"
  | "no-cache"
  | "standby"
  | "cache-and-network"
  | undefined;

const useHomepage = ({
  gender,
}: HomepageVars): Omit<HomepageHook, "refetch"> => {
  /**
   * Pupose of changin fetchPolicy on the client:
   * first render will be ssr garantee, once on the client the fetchPolicy will change
   * Why ?
   * there's a bug on the home page cache, the apollo cache update fields that are not supposed
   * to be updated when switching from women to kids section then back to women;
   * once on kids section the top banner image changes to kids image if both have same brand id
   * if the first render uses fetch on the client, the top banner render will be delayed and flicker
   */
  const [fetchPolicy, setFetchPolicy] = useState<Cache>(undefined);
  useEffect(() => {
    setFetchPolicy("cache-and-network");
  }, []);

  const { loading, error, data } = useQuery<HomepageData, HomepageVars>(
    GET_HOMEPAGE,
    {
      variables: {
        gender,
      },
      ssr: true,
      fetchPolicy,
      skip: !gender,
    }
  );

  return { loading, error, data };
};

const useNewInHomepage = ({
  gender,
  limit,
  labels,
  carousel_recommend_type,
}: HomepageVars): HomepageHook => {
  const { homepageNewInCarouselRecommender } = useFeatureFlags();
  const variables = {
    gender,
    limit,
    labels,
    carousel_recommend_type: {
      homepage_new_in_carousel:
        typeof homepageNewInCarouselRecommender === "string"
          ? homepageNewInCarouselRecommender
          : null,
    },
  };

  const { loading, error, data, refetch } = useQuery<
    HomepageData,
    HomepageVars
  >(GET_NEW_IN, {
    variables,
    ssr: true,
    fetchPolicy: "cache-and-network",
    skip: !gender,
  });

  return { loading, error, data, refetch };
};

const useShopsHomepage = ({ gender }: HomepageVars): HomepageHook => {
  const { loading, error, data, refetch } = useQuery<
    HomepageData,
    HomepageVars
  >(GET_SHOPS_HOMEPAGE, {
    variables: {
      gender,
    },
    ssr: true,
    fetchPolicy: "cache-and-network",
    skip: !gender,
  });

  return { loading, error, data, refetch };
};

export { useHomepage, useNewInHomepage, useShopsHomepage };
