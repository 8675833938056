import styled from "@emotion/styled";

const StyledImageContainer = styled.div`
  width: 100%;

  @media (max-width: ${({ theme }) => theme.breakpoints[2]}) {
    height: 280px;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    height: 328px;
  }
`;

const StyledImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export { StyledImageContainer, StyledImage };
