import { useState, useRef, useEffect, JSX } from "react";
import { debounce } from "lodash-es";
import { Box } from "@otrium/core";
import { ArrowBack, ArrowNext } from "src/icons";
import { StyledWrapper, Item, NavButton } from "./FilterList.styled";

export interface Item {
  key: string;
  content: string | JSX.Element;
  active?: boolean;
  onClick?: (key: string) => void;
}

interface Props {
  items: Item[];
}

const FilterList = ({ items }: Props): JSX.Element => {
  const wrapper = useRef<HTMLDivElement>(null);
  const [scrollState, setScollState] = useState<
    "start" | "end" | "intermidiate" | "hidden"
  >("start");

  const handleScroll = debounce((e: React.UIEvent<HTMLDivElement>) => {
    const { scrollWidth, scrollLeft, clientWidth } = e.target as HTMLDivElement;

    if (scrollWidth - scrollLeft === clientWidth) {
      setScollState("end");
    } else if (scrollLeft === 0) {
      setScollState("start");
    } else if (scrollWidth === clientWidth) {
      setScollState("hidden");
    } else {
      setScollState("intermidiate");
    }
  }, 320);

  useEffect(() => {
    const handler = debounce(() => {
      if (!wrapper.current) return;
      const { scrollWidth, clientWidth } = wrapper.current;

      if (scrollWidth === clientWidth) {
        setScollState("hidden");
      } else {
        wrapper.current.scrollLeft = 0;
        setScollState("start");
      }
    }, 300);

    window.addEventListener("resize", handler);

    handler();

    return () => {
      window.removeEventListener("resize", handler);
    };
  }, []);

  const handleNavigation = (type: "prev" | "next") => {
    if (!wrapper.current) return;

    const { scrollLeft } = wrapper.current;

    wrapper.current.scrollTo({
      top: 0,
      left: type === "next" ? scrollLeft + 120 : scrollLeft - 120,
      behavior: "smooth",
    });
  };

  return (
    <Box as="div" sx={{ position: "relative" }}>
      <StyledWrapper ref={wrapper} flexWrap="nowrap" onScroll={handleScroll}>
        {items.map(({ key, onClick = () => {}, active, content }) => (
          <Item
            key={key}
            onPress={() => onClick(key)}
            active={active}
            type="button"
          >
            {content}
          </Item>
        ))}
      </StyledWrapper>
      {scrollState !== "start" && scrollState !== "hidden" && (
        <NavButton
          direction="prev"
          role="navigation"
          onPress={() => handleNavigation("prev")}
        >
          <ArrowBack />
        </NavButton>
      )}

      {scrollState !== "end" && scrollState !== "hidden" && (
        <NavButton
          direction="next"
          role="navigation"
          onPress={() => handleNavigation("next")}
        >
          <ArrowNext />
        </NavButton>
      )}
    </Box>
  );
};

export default FilterList;
