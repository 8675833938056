import styled from "@emotion/styled";
import { HyperLink } from "src/atoms/HyperLink";
import { Box, Flex } from "@otrium/core";

const StyledCuratedCategoryHeader = styled(Box)`
  position: relative;
`;

const StyledImageContainer = styled.div`
  height: 350px;
  width: 100%;
  position: relative;

  :before {
    width: 100%;
    height: 50%;
    content: "";
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    opacity: 0.6;
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), #000000);
  }
`;

const StyledImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top;
`;

const StyledTitleWrapper = styled(Flex)`
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;

  @media (max-width: ${({ theme }) => theme.breakpoints[1]}) {
    margin: 20px ${({ theme }) => theme.space.space16} 0;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
    flex-direction: row;
    align-items: flex-end;
    flex-direction: column;
    align-items: start;
    position: absolute;
    bottom: ${({ theme }) => theme.space[4]}px;
    left: ${({ theme }) => theme.space[5]}px;

    svg {
      display: none;
    }

    h2,
    h3,
    div {
      color: ${({ theme }) => theme.colors.tone.white};
      border-color: ${({ theme }) => theme.colors.tone.white};
    }
  }
`;

const StyledViewAll = styled(HyperLink)`
  &,
  &:hover {
    text-decoration-color: ${({ theme }) => theme.colors.tone.white};
  }

  @media (max-width: ${({ theme }) => theme.breakpoints[1]}) {
    display: none;
  }
`;

export {
  StyledCuratedCategoryHeader,
  StyledImageContainer,
  StyledImage,
  StyledTitleWrapper,
  StyledViewAll,
};
