import { useContext } from "react";
import { useRouter } from "next/router";
import { AppCtx } from "src/contexts/app.context";
import AllBrandsSearch from "src/organisms/AllBrandsSearch";
import { GetServerSideProps } from "next";
import { HomeView } from "src/organisms/HomeView";
import Error from "pages/_error";

interface Props {
  statusCode?: number;
}

const genderTranslations = [
  "women",
  "femme",
  "damen",
  "dames",
  "kobiety",
  "mujer",
  "donna",
  "dame",
  "kvinna",
  "kids",
  "enfant",
  "kinder",
  "kids",
  "ninos",
  "bambini",
  "dzieci",
  "born",
  "barn",
  "men",
  "harren",
  "herren",
  "homme",
  "heren",
  "hombre",
  "uomo",
  "man",
  "mezczyzni",
];

const allBrandsTranslations = [
  "brands",
  "marques",
  "marken",
  "merken",
  "marki",
  "marcas",
  "marche",
  "brand",
  "maerker",
  "varumarken",
];

const AllBrandsPage = (): JSX.Element => {
  const { userPreferences } = useContext(AppCtx);
  const router = useRouter();
  const { slug } = router.query;
  const genderId = userPreferences?.gender;

  // Route for all "/brands"
  if (allBrandsTranslations.includes(slug as string)) {
    return (
      <AllBrandsSearch genderId={(slug as string) || genderId || "women"} />
    );
  }

  if (genderTranslations.includes(slug as string)) {
    return <HomeView />;
  }

  return <Error />;
};

export const getServerSideProps: GetServerSideProps<Props> = async (
  context
  // eslint-disable-next-line @typescript-eslint/require-await
) => {
  const { params, res } = context;
  const { slug } = params as { slug: string };

  if (![...allBrandsTranslations, ...genderTranslations].includes(slug)) {
    res.statusCode = 404;
  }

  return {
    props: {
      statusCode: res.statusCode,
    },
  };
};

export default AllBrandsPage;
