import { Flex } from "@otrium/core";
import styled from "@emotion/styled";
import BaseButton from "src/atoms/Button/BaseButton";

export const StyledWrapper = styled(Flex)`
  gap: ${({ theme }) => `${theme.space.space8}`};
  overflow-x: auto;
  padding-right: ${({ theme }) => `${theme.space.space16}`};
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

export const Item = styled(BaseButton)<{ active?: boolean }>`
  padding: ${({ theme }) => `${theme.space.space8} ${theme.space.space16}`};
  border: ${({ theme }) => `2px solid ${theme.colors.tone.black}`};
  color: ${({ theme, active }) =>
    active ? theme.colors.tone.white : theme.colors.tone.black};
  border-radius: 4px;
  min-width: auto;
  display: block;
  font-weight: 600;
  background-color: ${({ theme, active }) =>
    active ? theme.colors.tone.black : "transparent"};
  cursor: pointer;
  white-space: nowrap;
`;

export const NavButton = styled(BaseButton)<{ direction: "prev" | "next" }>(
  ({ direction, theme }) => ({
    position: "absolute",
    top: 0,
    left: direction === "prev" ? `-${theme.space.space16}` : "auto",
    right: direction === "next" ? `-${theme.space.space16}` : "auto",
    height: "100%",
    backgroundColor: theme.colors.tone.white,
    cursor: "pointer",
    fontWeight: 600,
    display: "flex",
    border: "none",
    outline: "none",
    padding: "10px 10px",
    alignItems: "center",
    paddingLeft: direction === "next" ? "20px" : "10px",
    paddingRight: direction === "prev" ? "20px" : "10px",
    background:
      direction === "next"
        ? "linear-gradient(90deg,rgba(255, 255, 255, 0.0) 0%, rgba(255, 255, 255, 1) 40%, rgba(255, 255, 255, 1) 100%)"
        : "linear-gradient(90deg,rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 60%, rgba(255, 255, 255, 0.0) 100%)",
  })
);
