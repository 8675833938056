import { FC, useContext, useState } from "react";
import { Trans } from "@lingui/macro";
import Link from "next/link";
import { Button } from "src/atoms/Button";
import { Box } from "@otrium/core";
import { AppCtx } from "src/contexts/app.context";
import { HomePageSingleBrand } from "src/types/graphql.d";
import { getI18nRoute } from "src/utils/i18nRoutes";
import { BrandCover } from "src/molecules/BrandCover";
import {
  BrandInjectedProps,
  SEGMENT_POSITIONS,
  SEGMENT_PROMOTION_TYPE,
} from "src/segment";
import SegmentBrandTracking from "src/atoms/SegmentTracking/SegmentBrandTracking";
import { LockIcon, LockUnlockIcon } from "@otrium/icons";
import { useTheme, Theme } from "@emotion/react";
import { LoginRegisterModal } from "../LoginRegisterModal";

interface Props {
  brand: HomePageSingleBrand;
  genderId: string;
  isComingSoon: boolean;
  isGated?: boolean;
  isMobile?: boolean;
  setRedirectData?: (type: string) => void;
}

const HomeBrandCover: FC<Props> = (props) => {
  const { locale } = useContext(AppCtx);
  const { brand } = props;
  const theme: Theme = useTheme();
  const [open, setOpen] = useState<boolean>(false);
  const [hovered, setHovered] = useState(false);
  const [actionSource, setActionSource] = useState<string>("");

  const redirectUrl = `${getI18nRoute({
    route: `/brands`,
    locale,
  })}/${brand.slug}${getI18nRoute({
    route: `/${props.genderId}`,
    locale,
  })}`;

  const handleContextMenu = (event: React.MouseEvent) => {
    event.preventDefault();
    setOpen(true);
  };

  const handleMouseEnter = () => {
    if (!props.isMobile) {
      setHovered(true);
    }
  };

  const handleMouseLeave = () => {
    if (!props.isMobile) {
      setHovered(false);
    }
  };

  return (
    <>
      <SegmentBrandTracking
        brand={brand}
        cover={true}
        position={`${SEGMENT_POSITIONS.featuredShop}-1`}
        promotionType={SEGMENT_PROMOTION_TYPE.featuredShop}
      >
        {props.isGated
          ? () => (
              <Box
                data-testid="featured-brand-promotion"
                onClick={() => {
                  setOpen(true);
                  setActionSource("feature_brand");
                }}
                onContextMenu={handleContextMenu}
              >
                <BrandCover
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                  disablePaddingSM
                  {...props}
                  isGated={props.isGated}
                  isMobile={props.isMobile}
                  actions={
                    <Button
                      variant="secondary"
                      colorScheme="light"
                      leftIcon={
                        hovered ? (
                          <LockUnlockIcon color={theme.colors.tone.black} />
                        ) : (
                          <LockIcon color={theme.colors.tone.white} />
                        )
                      }
                      className="login-btn"
                      onClick={(e) => {
                        e.stopPropagation();
                        setOpen(true);
                        setActionSource("feature_brand_cta");
                      }}
                    >
                      <Trans>Log in for full access</Trans>
                    </Button>
                  }
                  minimizeHeightOnMobile={false}
                />
              </Box>
            )
          : ({ onClickHandler }: BrandInjectedProps) => (
              <Link href={redirectUrl} passHref legacyBehavior>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid,jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
                <a onClick={onClickHandler}>
                  <BrandCover
                    disablePaddingSM
                    {...props}
                    actions={
                      <Box>
                        {(brand?.subtitle || brand?.discount) && (
                          <Button
                            as="div"
                            variant="secondary"
                            colorScheme="light"
                          >
                            <Trans>
                              up to {brand?.subtitle || brand.discount} discount
                            </Trans>
                          </Button>
                        )}
                      </Box>
                    }
                    minimizeHeightOnMobile={false}
                  />
                </a>
              </Link>
            )}
      </SegmentBrandTracking>
      {open && (
        <LoginRegisterModal
          open={open}
          onClose={() => {
            setOpen(false);
            setActionSource("");
          }}
          redirectTo={redirectUrl}
          trackingData={{
            pageName: "homepage",
            shopType: props.genderId,
            actionSource,
            redirectTo: redirectUrl,
            level: brand.gate?.level || "non_gated",
            brandName: brand.name,
            brandId: brand.id,
            brandImageUrl: brand.image,
          }}
          onRedirect={props.setRedirectData}
        />
      )}
    </>
  );
};

export default HomeBrandCover;
