type FocalPoint =
  | "center"
  | "top"
  | "right"
  | "left"
  | "bottom"
  | "top_right"
  | "top_left"
  | "bottom_right"
  | "bottom_left";

export interface NewImageParameters {
  width: string;
  height: string;
  fit?: string;
  focalPoint: FocalPoint;
}

const getImageWithUpdatedParameters = (
  imageLink: string,
  newParameters: NewImageParameters
): string => {
  const url = new URL(imageLink);
  url.searchParams.set("w", newParameters.width);
  url.searchParams.set("h", newParameters.height);
  url.searchParams.set("f", newParameters.focalPoint);
  if (newParameters.fit) {
    url.searchParams.set("fit", newParameters.fit);
  }

  return url.toString();
};

export default getImageWithUpdatedParameters;
